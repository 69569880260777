<template>
  <div class="mailing">
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="#006FC0"
    ></v-progress-linear>
    <div class="content">
      <!-- кнопка вызова модалки добавления пользователей -->
      <v-btn
        color="white"
        class="btn"
        @click="
          editedId = null;
          mailingModalShow = true;
        "
      >
        <v-icon class="btn__icon">mdi-plus</v-icon>
        <span class="btn__text">Добавить</span>
      </v-btn>
      <!-- Фильтрация табличных данных -->
      <div class="filters">
        <v-btn
          text
          color="rgb(0,0,0,0.58)"
          class="filters__filter"
          @click="pickedFilter = 'all'"
          :class="{ active: pickedFilter == 'all' }"
        >
          <span>Все</span>
        </v-btn>
        <v-btn
          text
          color="rgb(0,0,0,0.58)"
          class="filters__filter"
          @click="pickedFilter = 'sent'"
          :class="{ active: pickedFilter == 'sent' }"
        >
          <span>Отправлено</span>
        </v-btn>
        <v-btn
          text
          color="rgb(0,0,0,0.58)"
          class="filters__filter"
          @click="pickedFilter = 'draft'"
          :class="{ active: pickedFilter == 'draft' }"
        >
          <span>Черновики</span>
        </v-btn>
        <!-- выбор даты -->
        <div class="datepicker-wrapper">
          <v-dialog
            ref="dialog"
            v-model="dateFilterModal"
            :return-value.sync="filterDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="ПЕРИОД"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              range
              selected-items-text="Период выбран"
              locale="ru-Latn"
              first-day-of-week="1"
              v-model="filterDate"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dateFilterModal = false">
                Отмена
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.dialog.save(filterDate);
                  pickedFilter = 'date';
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
      </div>
      <!-- таблица с рассылками -->
      <div class="table-wrapper" v-show="mailing.length > 0">
        <v-simple-table class="table" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 235 ? 'shadow' : null"
                >
                  №
                </th>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 235 ? 'shadow' : null"
                >
                  Название
                </th>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 235 ? 'shadow' : null"
                >
                  Статус
                </th>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 235 ? 'shadow' : null"
                >
                  Доставлено
                </th>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 235 ? 'shadow' : null"
                >
                  Дата и время отправки
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in filteredData.slice(0, sliceValue)"
                :key="item.id"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ item.title }}</td>
                <td>
                  {{
                    item.status == "draft"
                      ? "Черновик"
                      : item.status == "unsent"
                      ? "Не отправлено"
                      : "Отправлено"
                  }}
                </td>
                <td>{{ item.sent_count }}/{{ item.unsent_count }}</td>
                <td
                  style="
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div class="text">
                    {{ item.send_at.split("T")[0] | convertedDate() }}
                    {{ item.send_at.split("T")[1].slice(0, 5) }}
                  </div>
                  <div class="actions">
                    <v-btn
                      class="btn"
                      text
                      @click="
                        editedId = item.id;
                        mailingModalShow = true;
                      "
                      ><v-icon size="24" color="rgba(0, 0, 0, 0.6)"
                        >mdi-pencil</v-icon
                      ></v-btn
                    >
                    <v-btn
                      class="btn"
                      text
                      @click="
                        deletingId = item.id;
                        deleteModalShow = true;
                      "
                      ><v-icon size="24" color="rgba(0, 0, 0, 0.6)"
                        >mdi-delete</v-icon
                      ></v-btn
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <!-- если нет категорий созданных -->
    <div class="noCards" v-show="mailing.length === 0 && !mailingModalShow">
      <div class="text">
        Для добавления первой рассылки нажмите кнопку “Добавить”
      </div>
    </div>
    <new-mailing-modal
      :show="mailingModalShow"
      :editedId="editedId"
      @closed="mailingModalShow = false"
      @addNew="addMailing($event)"
      @edit="editMailing($event)"
    />
    <!-- удаление -->
    <delete-modal
      :show="deleteModalShow"
      @closed="deleteModalShow = false"
      @accepted="deleteMailing(deletingId)"
    />
  </div>
</template>

<script>
import axios from "axios";
import newMailingModal from "@/components/Mailing/newMailingModal";
import deleteModal from "../components/Mailing/deleteModal.vue";
export default {
  components: {
    newMailingModal,
    deleteModal,
  },
  data() {
    return {
      mailingModalShow: false,
      editedId: null,

      mailing: [],
      pickedFilter: "all",
      filterDate: [],
      dateFilterModal: false,
      deleteModalShow: false,

      fileToUpload: null,
      loading: false,
      sliceValue: 50,
      scrollPosition: null,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
      /* Если высота окна + высота прокрутки больше или равны высоте всего документа, то  */
      if (
        window.innerHeight + window.scrollY >= document.body.clientHeight &&
        this.mailing.length > this.sliceValue
      ) {
        this.sliceValue += 50;
      }
    },
    getMailing() {
      return new Promise((resolve, reject) => {
        axios
          .get("/mailings/")
          .then((resp) => {
            this.mailing = resp.data;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    addMailing(data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/mailings/", data)
          .then((resp) => {
            this.mailingModalShow = false;
            this.getMailing();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    editMailing(data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/mailings/${data.id}`, data)
          .then((resp) => {
            this.mailingModalShow = false;
            this.getMailing();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    deleteMailing(id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/mailings/${id}`)
          .then((resp) => {
            this.deleteModalShow = false;
            this.getMailing();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    convertDate(rowDate) {
      let dateParts = rowDate.split("-");
      return [dateParts[2], dateParts[1], dateParts[0]].join(".");
    },
  },
  filters: {
    convertedDate(rowDate) {
      let dateParts = rowDate.split("-");
      return [dateParts[2], dateParts[1], dateParts[0]].join(".");
    },
  },
  computed: {
    dateRangeText() {
      if(this.filterDate.length < 2) return
      let startDate = new Date(this.filterDate[0]).setHours(0, 0, 0, 0);
      let endDate = new Date(this.filterDate[1]).setHours(0, 0, 0, 0);
      if (+startDate >= endDate) {
        return (
          this.convertDate(this.filterDate[1]) +
          " — " +
          this.convertDate(this.filterDate[0])
        );
      } else {
        return (
          this.convertDate(this.filterDate[0]) +
          " — " +
          this.convertDate(this.filterDate[1])
        );
      }
    },
    filteredData() {
      if (this.pickedFilter == "draft") {
        return this.mailing.filter((item) => {
          if (item.status == "draft") return true;
        });
      } else if (this.pickedFilter == "sent") {
        return this.mailing.filter((item) => {
          if (item.status == "sent") return true;
        });
      } else if (this.pickedFilter == "date") {
        return this.mailing.filter((item) => {
          let mailingDate = new Date(item.send_at).setHours(0, 0, 0, 0);
          let startDate = new Date(this.filterDate[0]).setHours(0, 0, 0, 0);
          let endDate = new Date(this.filterDate[1]).setHours(0, 0, 0, 0);
          if(+startDate >= +endDate){
            let tmp = startDate
            startDate = endDate
            endDate = tmp
          }
          if (+mailingDate >= +startDate && +mailingDate <= +endDate)
            return true;
        });
      } else {
        return this.mailing;
      }
    },
  },

  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  created() {
    this.getMailing();
  },
};
</script>

<style lang="scss" scoped>
.mailing {
  position: relative;
  height: 100%;
  .content {
    padding: 87px 243px 118px 243px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.v-data-table .sticky-header {
  position: sticky;
  top: 70px;
  background: white;
  z-index: 2;
  &.shadow {
    box-shadow: 3px 5px 4px 0px rgba(0, 0, 0, 0.2) !important;
  }

  // border-bottom: thin solid rgba(0, 0, 0, 0.2);
}
.table {
  &-wrapper {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    background: white;
    border-radius: 5px;
    // margin-bottom: 75px;
  }
  thead {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  th {
    padding: 13px 23px !important;
    text-transform: uppercase;
  }
  th:nth-child(1) {
    border-top-left-radius: 5px;
  }
  th:nth-child(6) {
    border-top-right-radius: 5px;
  }
  td {
    padding: 0 23px !important;
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .btn {
      min-width: 24px !important;
      width: 24px !important;
      height: 24px !important;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.noCards {
  z-index: 10;
  position: absolute;
  width: 100%;
  background: rgba(108, 108, 108, 0.15);
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    max-width: 408px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #333333;
    margin-bottom: 32px;
  }
}
.btn {
  padding: 0 !important;
  align-self: flex-end;
  width: 167px;
  height: 47px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  &__icon {
    color: #006fc0 !important;
    font-size: 37px !important;
    margin-right: 11px !important;
  }
  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #006fc0 !important;
    margin-right: 8px;
  }
}
.filters {
  margin: 22px 0 14px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  &__filter {
    &.active {
      color: #006fc0 !important;
    }
    &::before {
      background-color: transparent !important;
    }
    &.active::before {
      background-color: transparent !important;
    }
    &:hover.active {
      color: #4b99d2 !important;
    }
    &:hover {
      color: #4b99d2 !important;
      background: transparent;
    }
  }
  .datepicker-wrapper {
    width: 230px;
  }
}
</style>
