<template>
  <v-row justify="center">
    <v-dialog
      content-class="dialog"
      max-width="788"
      min-height="500"
      :value="show"
      @click:outside="$emit('closed')"
    >
      <v-card>
        <h3 class="title">Новая рассылка</h3>
        <v-form ref="form" style="width: 100%" v-model="valid">
          <p class="subtitle">Название рассылки</p>
          <v-text-field
            class="input"
            color="#006FC0"
            outlined
            placeholder="Название рассылки"
            name="name"
            type="text"
            :rules="[rules.required]"
            v-model="title"
          ></v-text-field>
          <p class="subtitle">Текст рассылки</p>
          <v-textarea
            class="input"
            rows="3"
            auto-grow
            color="#006FC0"
            outlined
            placeholder="Текст рассылки"
            name="text"
            type="text"
            counter="1000"
            :rules="[rules.required]"
            v-model="text"
          ></v-textarea>
          <!-- добавление фото -->
          <div class="photos">
            <div class="photo" v-if="image_url">
              <img class="photo__img" :src="image_url" alt="image" />
              <v-icon @click="image_url = ''" class="photo__close"
                >mdi-close</v-icon
              >
            </div>

            <add-photo v-else @uploaded="image_url = $event" />
          </div>

          <!-- выбор даты -->
          <p class="subtitle">Выберите дату и время рассылки</p>
          <div class="datetime-wrapper">
            <div class="date">
              <v-dialog
                ref="dialogDate"
                v-model="dateModal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="dateText"
                    label="Дата"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    :rules="[rules.required]"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="ru-Latn"
                  :allowed-dates="allowedDates"
                  first-day-of-week="1"
                  v-model="date"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dateModal = false">
                    Отмена
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialogDate.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>

            <div class="time">
              <v-dialog
                ref="dialogTime"
                v-model="timeModal"
                :return-value.sync="time"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time"
                    label="Время"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    :rules="[rules.required]"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="time"
                  label="Время"
                  format="24hr"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="timeModal = false">
                    Отмена
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialogTime.save(time)"
                  >
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </div>
          </div>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="height: 42px"
            color="#006FC0"
            text
            :disabled="!valid"
            @click="submit('draft')"
          >
            Сохранить в черновик
          </v-btn>

          <v-btn
            class="submin-btn"
            color="#006FC0"
            :disabled="!valid"
            @click="submit('unsent')"
          >
            Отправить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
import addPhoto from "./addPhoto";
export default {
  props: ["show", "editedId"],
  components: { addPhoto },
  data() {
    return {
      title: "",
      text: "",
      time: "",
      date: "",
      image_url: "",
      dateModal: false,
      timeModal: false,
      rules: {
        required: (value) => !!value || "Обязательное поле",
      },
      valid: false,
    };
  },
  methods: {
    submit(status) {
      let send_at = this.date + "T" + this.time + ":00Z";
      if (this.valid) {
        if (this.editedId == null) {
          this.$emit("addNew", {
            title: this.title,
            text: this.text,
            status: status,
            image_url: this.image_url,
            send_at: send_at,
          });
        } else {
          this.$emit("edit", {
            id: this.editedId,
            title: this.title,
            text: this.text,
            status: status,
            image_url: this.image_url,
            send_at: send_at,
          });
        }

        this.$refs.form.reset();
      }
    },
    allowedDates(val) {
      let now = new Date().setHours(0, 0, 0, 0);
      let dateSplitted = val.split("-");
      let date = new Date(
        dateSplitted[0],
        dateSplitted[1] - 1,
        dateSplitted[2],
        0,
        0,
        0,
        0
      );
      return +date >= +now;
    },
    allowedHours(val) {
      let currentHours = new Date().getHours();
      return val > currentHours;
    },
    getMailing() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/mailings/${this.editedId}`)
          .then((resp) => {
            let data = resp.data;
            this.title = data.title;
            this.text = data.text;
            this.time = data.send_at.split("T")[1].slice(0, 5);
            this.date = data.send_at.split("T")[0];
            this.image_url = data.image_url;
            this.$refs.form.resetValidation();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
  },
  watch: {
    show: function() {
      if (this.show && this.editedId !== null) {
        this.getMailing();
      } else {
        this.title = "";
        this.text = "";
        this.time = "";
        this.date = "";
        this.image_url = "";
        setTimeout(() => {
          this.$refs.form.reset();
        }, 100);
      }
    },
  },
  computed: {
    // valid() {
    //   return (
    //     this.title !== "" &&
    //     this.text.length > 0 &&
    //     this.text.length <= 1000 &&
    //     this.time !== "" &&
    //     this.date !== ""
    //   );
    // },
    dateText() {
      let parts = this.date.split("-");
      return this.date == "" ? "" : [parts[2], parts[1], parts[0]].join("-");
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .v-card {
    width: 788px !important;
    // min-height: 500px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    padding: 34px 38px 26px 39px;
    &__actions {
      align-self: flex-end;
      padding: 0 !important;
      margin-top: 17px;
    }
    .title {
      align-self: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      color: #006fc0;
      margin-bottom: 62px;
    }
    .subtitle {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #006fc0;
      opacity: 0.8;
      padding: 0;
      margin: 0;
    }
    .datetime-wrapper {
      display: flex;
      flex-direction: row;
      .date {
        width: 120px;
      }
      .time {
        width: 90px;
        margin-left: 13px;
      }
    }
    .photos {
      margin-bottom: 30px;
      .photo {
        display: flex;
        align-items: center;
        &__img {
          border-radius: 4px;
          width: 123px;
          height: 87px;
          object-fit: cover;
          margin-right: 15px;
        }
        &__close {
          opacity: 0.2;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
  .input {
    width: 100%;
  }
  .submin-btn {
    height: 42px;
    color: white !important;
    &.v-btn--disabled {
      background: #7b7b7b !important;
    }
  }
}
</style>
