<template>
  <v-row justify="center">
    <v-dialog
      content-class="dialog"
      max-width="484"
      min-height="205"
      :value="show"
      @click:outside="$emit('closed')"
    >
      <v-card>
        <div class="title">Удалить навсегда?</div>
        <div class="subtitle">
          Выбранная рассылка будет удалена без возможности восстановления.
        </div>
    
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="height: 42px"
            color="black"
            text
            @click="$emit('closed')"
          >
            Отменить
          </v-btn>

          <v-btn class="submin-btn" text @click="$emit('accepted')">
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["show"],
};
</script>

<style lang="scss" scoped>
.dialog {
  .v-card {
    width: 484px !important;
    min-height: 205px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start !important;
    padding: 30px 23px 26px 30px;
    &__actions {
      align-self: flex-end;
      padding: 0 !important;
      margin-top: 17px;
    }
  }
  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #333333;
  }
  .subtitle {
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    max-width: 342px;
    margin-top: 16px;
  }
  .submin-btn {
    height: 42px;
    color: white;
    background: #ff5252;
  }
}
</style>