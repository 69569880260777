var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mailing"},[_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","top":"","color":"#006FC0"}}),_c('div',{staticClass:"content"},[_c('v-btn',{staticClass:"btn",attrs:{"color":"white"},on:{"click":function($event){_vm.editedId = null;
        _vm.mailingModalShow = true;}}},[_c('v-icon',{staticClass:"btn__icon"},[_vm._v("mdi-plus")]),_c('span',{staticClass:"btn__text"},[_vm._v("Добавить")])],1),_c('div',{staticClass:"filters"},[_c('v-btn',{staticClass:"filters__filter",class:{ active: _vm.pickedFilter == 'all' },attrs:{"text":"","color":"rgb(0,0,0,0.58)"},on:{"click":function($event){_vm.pickedFilter = 'all'}}},[_c('span',[_vm._v("Все")])]),_c('v-btn',{staticClass:"filters__filter",class:{ active: _vm.pickedFilter == 'sent' },attrs:{"text":"","color":"rgb(0,0,0,0.58)"},on:{"click":function($event){_vm.pickedFilter = 'sent'}}},[_c('span',[_vm._v("Отправлено")])]),_c('v-btn',{staticClass:"filters__filter",class:{ active: _vm.pickedFilter == 'draft' },attrs:{"text":"","color":"rgb(0,0,0,0.58)"},on:{"click":function($event){_vm.pickedFilter = 'draft'}}},[_c('span',[_vm._v("Черновики")])]),_c('div',{staticClass:"datepicker-wrapper"},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.filterDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.filterDate=$event},"update:return-value":function($event){_vm.filterDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"ПЕРИОД","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateFilterModal),callback:function ($$v) {_vm.dateFilterModal=$$v},expression:"dateFilterModal"}},[_c('v-date-picker',{attrs:{"range":"","selected-items-text":"Период выбран","locale":"ru-Latn","first-day-of-week":"1","scrollable":""},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateFilterModal = false}}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.filterDate);
                _vm.pickedFilter = 'date';}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.mailing.length > 0),expression:"mailing.length > 0"}],staticClass:"table-wrapper"},[_c('v-simple-table',{staticClass:"table",attrs:{"fixed-header":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"sticky-header text-left",class:_vm.scrollPosition >= 235 ? 'shadow' : null},[_vm._v(" № ")]),_c('th',{staticClass:"sticky-header text-left",class:_vm.scrollPosition >= 235 ? 'shadow' : null},[_vm._v(" Название ")]),_c('th',{staticClass:"sticky-header text-left",class:_vm.scrollPosition >= 235 ? 'shadow' : null},[_vm._v(" Статус ")]),_c('th',{staticClass:"sticky-header text-left",class:_vm.scrollPosition >= 235 ? 'shadow' : null},[_vm._v(" Доставлено ")]),_c('th',{staticClass:"sticky-header text-left",class:_vm.scrollPosition >= 235 ? 'shadow' : null},[_vm._v(" Дата и время отправки ")])])]),_c('tbody',_vm._l((_vm.filteredData.slice(0, _vm.sliceValue)),function(item,index){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.title))]),_c('td',[_vm._v(" "+_vm._s(item.status == "draft" ? "Черновик" : item.status == "unsent" ? "Не отправлено" : "Отправлено")+" ")]),_c('td',[_vm._v(_vm._s(item.sent_count)+"/"+_vm._s(item.unsent_count))]),_c('td',{staticStyle:{"text-transform":"uppercase","display":"flex","align-items":"center","justify-content":"space-between"}},[_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm._f("convertedDate")(item.send_at.split("T")[0]))+" "+_vm._s(item.send_at.split("T")[1].slice(0, 5))+" ")]),_c('div',{staticClass:"actions"},[_c('v-btn',{staticClass:"btn",attrs:{"text":""},on:{"click":function($event){_vm.editedId = item.id;
                      _vm.mailingModalShow = true;}}},[_c('v-icon',{attrs:{"size":"24","color":"rgba(0, 0, 0, 0.6)"}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"btn",attrs:{"text":""},on:{"click":function($event){_vm.deletingId = item.id;
                      _vm.deleteModalShow = true;}}},[_c('v-icon',{attrs:{"size":"24","color":"rgba(0, 0, 0, 0.6)"}},[_vm._v("mdi-delete")])],1)],1)])])}),0)]},proxy:true}])})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.mailing.length === 0 && !_vm.mailingModalShow),expression:"mailing.length === 0 && !mailingModalShow"}],staticClass:"noCards"},[_c('div',{staticClass:"text"},[_vm._v(" Для добавления первой рассылки нажмите кнопку “Добавить” ")])]),_c('new-mailing-modal',{attrs:{"show":_vm.mailingModalShow,"editedId":_vm.editedId},on:{"closed":function($event){_vm.mailingModalShow = false},"addNew":function($event){return _vm.addMailing($event)},"edit":function($event){return _vm.editMailing($event)}}}),_c('delete-modal',{attrs:{"show":_vm.deleteModalShow},on:{"closed":function($event){_vm.deleteModalShow = false},"accepted":function($event){return _vm.deleteMailing(_vm.deletingId)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }