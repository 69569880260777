<template>
  <div class="btnUpload">
    <!-- TODO: FILE SIZE max 25mb -->
    <input
      class="btnUpload__input"
      type="file"
      :id="'file_' + id"
      ref="file"
      v-on:change="handleFileUpload()"
      accept="image/*"
    />
    <v-btn color="white" text class="btn" @click="$refs.file.click()">
      <div class="icon"></div>
      <span class="btn__text">Добавить изображение</span>
    </v-btn>
     <notification
      :show="showNotification"
      :text="notificationText"
      @close="showNotification = false"
    />
  </div>
</template>

<script>
import notification from "@/components/Knowledge/notification";
import axios from "axios";
export default {
  components: {
    notification
  },
  props: {
    id: {
      type: String,
      default: "1",
    },
  },
  data() {
    return {
      file: {
        ref: "",
        name: "",
      },
      showNotification: false,
      notificationText: 'Максимальный размер изображения 25 МБ'
    };
  },
  methods: {
    handleFileUpload() {
      this.file.ref = this.$refs.file.files[0];
      this.file.name = this.$refs.file.files[0].name;
      let maxImgSize = 25000000; //bytes
      if( this.$refs.file.files[0].size > maxImgSize){
        this.showNotification = true
      }else{
       this.uploadPhoto();
      }
      
    },
    uploadPhoto() {
      const formData = new FormData();
      formData.append("file", this.file.ref);
      return new Promise((resolve, reject) => {
        axios
          .post("/mailings/upload_image", formData)
          .then((resp) => {
            this.$emit('uploaded',resp.data.url)
            this.$refs.file.value = "";
            resolve(resp);
          })
          .catch((err) => {
             this.$refs.file.value = "";
            reject(err);
            console.log(err);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btnUpload {
  position: relative;
  cursor: pointer;
  &__input {
    opacity: 0;
    width: 167px;
    height: 47px;
  }
  .btn {
    top: 0;
    position: absolute;
    width: 169px;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: none;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
    &__text {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #006fc0;
      margin-left: 8px;
      text-transform: none;
      letter-spacing: 0.58px;
      border-bottom: 1px solid #006fc0;
    }
    .icon {
      background: url("~@/assets/add_a_photo.svg");
      width: 24px;
      height: 24px;
    }
  }
}
</style>